.paypalIcon {
  height: 24px;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 16px 0;
}

.headerRow {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.cardIconContainer {
  width: 2.625em;
  height: 1.75em;
  margin-right: 1em;
}
