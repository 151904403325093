@import '../../../assets/styles/shared.scss';

.tos {
  margin-top: 20px;
  font-size: 12px;
  line-height: normal;
  padding-bottom: 20px;

  a {
    color: $vds-color-cerulean;
    text-decoration: none;
    font-weight: bold;
  }
}

.terms_line {
  display: flex;
}

.checkbox {
  width: 30px;
  height: 30px;
  margin: auto;
}

.footer {
  margin-bottom: 20px;
}
