@import '~assets/styles/shared';
@import 'sass-mq';

.auto_renew_container {
  max-width: 500px;
  margin: 32px auto 0;
  
  strong {
    font-weight: normal;
  }

  .checkbox {
    padding-bottom: $pad-s;
  }
}

.auto_renew_policy {
  font-size: $size-16;
  line-height: 2;
}

.tos_links {
  color: $vds-color-silver;
  font-size: $size-16;
  line-height: $size-24;
}
