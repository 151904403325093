@import '~assets/styles/shared';
@import 'sass-mq';

.main_card {
  padding: $pad-s $pad-l;

  .header_text {
    display: flex;
    align-items: center;
    font-size: $size-16;
    line-height: $size-28;
    color: $vds-color-slate;
    font-weight: 600;
    margin: 0;

    svg {
      margin-right: 10px;
      fill: $vds-color-green;
      height: 30px;
      width: 30px;
    }

    i {
      height: 40px;
    }
  }

  .info {
    color: $vds-color-slate;
    margin-top: 20px;
  }
}

.order_confirmation {
  padding: 30px;
}

.container {
  max-width: 700px;
}

// Center the loading icon horizontally and vertically
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 95vh;
}

.loading_child {
  margin: auto;
}
