@import '~assets/styles/shared';

.error_icon {
  color: darken($vds-color-red, 15%);
  font-size: $size-24;
  margin-right: 10px;

  &::before {
    line-height: $size-34;
    font-weight: 600;
  }
}

.full_page_error {
  max-width: $app-max-width;
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 50px;
  font-size: $size-28;
  line-height: $size-36;
  font-weight: 300;
  background-color: $vds-color-white;
  border: $border-gray;
  box-shadow: $box-shadow;

  h1 {
    font-size: inherit;
    font-weight: inherit;
  }
}

.error_message {
  display: flex;
  align-items: center;
  margin: 8px 0;
  padding: 15px 20px;
  font-size: $size-12;
  line-height: $size-22;
  color: darken($vds-color-red, 15%);
  border: 1px solid $vds-color-red;
  border-radius: $border-radius;
}
