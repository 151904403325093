@import '../../../assets/styles/shared.scss';

.tou {
  margin-top: 20px;
  font-size: 12px;
  line-height: normal;
  padding-bottom: 20px;

  a {
    color: $vds-color-cerulean;
    text-decoration: none;
    font-weight: bold;
  }
}
