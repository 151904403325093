@import '~assets/styles/shared';
@import 'sass-mq';

.cartContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0;
}

.couponContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: $pad-m
}

.continueBtn {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 16px 0;
}

.cartDetails {
  width: 100%;
  max-width: 500px;
}
