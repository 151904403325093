@import '~assets/styles/shared';
@import '~@udacity/veritas-components/dist/styles/index.scss';

.breadcrumbItem {
  + .breadcrumbItem::before {
    display: inline-block;
    content: '>';
    padding-left: 8px;
    padding-right: 8px;
  }
}

.selected {
  -webkit-text-stroke-width: 1px // hack to bold text without changing the text's width
}

.past {
}

.future {
  color: $vds-color-silver;
}

.wrapper {
  max-width: 640px;
  justify-content: space-between;
  display: flex;
  font-size: $size-16;
  font-family: 'Open Sans', sans-serif;
  color: $vds-color-cerulean-dark;
  margin-bottom: 10px;
  list-style: none;
  padding: 0;
}

.separator {
  margin: 0 8px;
}
