@import '../../../assets/styles/shared.scss';

.link_container {
  text-align: right;
}

.forgot_password {
  color: $vds-color-cerulean;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
}

.field + .field {
  margin-top: 20px;
}
