@import '~assets/styles/shared';

.reviewOrderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0;
}

.reviewDetails {
  width: 100%;
  max-width: 500px;
}

.errorMessage {
  padding: $pad-s $pad-l;
}

.button_container {
  margin: 0;
  padding: $pad-s $pad-l $pad-l $pad-l;

  .purchase_button {
    width: 100%;
    display: flex;
    margin-top: 0.5rem;
    padding: 1em 5em;
    justify-content: center;
  }
}

.buttonRow {
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    margin: 8px;
  }
}
