@import '~assets/styles/shared.scss';
@import 'sass-mq';

$outside-padding: 30px;
$vertical-padding-mobile: 15px;
$horizontal-padding: 15px;
$horizontal-padding-mobile: 20px;
$padding-mobile: $vertical-padding-mobile $horizontal-padding-mobile;

.order_confirmation {
  .order_header {
    padding: $outside-padding;
    color: $vds-color-slate;

    .header_text {
      font-size: $size-20;
      font-weight: bold;
      line-height: $size-28;
      color: $vds-color-slate;
    }
  }

  .subheader {
    @extend .subheader;
    margin-bottom: 10px;
  }

  .order_body {
    padding: 0 $outside-padding $outside-padding $outside-padding;
    border-radius: $border-radius-bottom;
    color: $vds-color-slate;

    .order_name {
      font-weight: bold;
    }

    :global .vds-button {
      margin: 40px auto 0;
      display: block;
      padding: 15px;
      width: 100%;
      max-width: 200px;

      @include mq($from: desktop) {
        margin: 40px 0 0;
      }
    }

    :global .vds-checkbox {
      margin-bottom: 1em;
    }
  }
}

.emphasis {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: auto;
}

.phone_number_editor {
  margin-bottom: 16px;
}

.light {
  font-size: 0.75rem;
  margin-bottom: 8px;
}

@include mq($until: desktop) {
  .order_overview {
    .order_header {
      padding: $padding-mobile;
      border-radius: 0;
    }

    .order_body {
      padding: $padding-mobile;
    }
  }
}
