@import '~assets/styles/shared.scss';

.feature {
  width: 100%;
  background-color: $vds-color-blue-lightest;
  margin: 32px 0;
  padding: 16px 8px;
}

.wrapperGrid {
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.wrapperGrid > div {
  display: flex;
  flex-basis: calc(50% - 40px);
  justify-content: left;
  flex-direction: row;
}
.wrapperGrid > div > div {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.box {
  margin: 16px 8px;
  img {
    margin: 16px
  }
  align-items: center;
}

.textCenter {
  text-align: center
}

@include mq($from: tablet) {
  .wrapperGrid {
    flex-direction: row;
  }
}
