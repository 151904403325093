@import '~assets/styles/shared.scss';

.header {
  font-size: 34px;
  font-weight: 300;
  line-height: 1.3;
  color: $vds-color-slate;
  text-align: center;
}

.description {
  font-size: 14px;
  line-height: 1.6;
  color: $vds-color-silver-light;
  margin: 15px 0;
  text-align: center;
}
