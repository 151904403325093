@import '~assets/styles/shared';

.phone_number_editor {
  display: flex;

  > div {
    display: flex;
    flex-grow: 1;

    > div {
      flex-grow: 1;

      // Flag container
      > div > div:not(:empty) {
        border: none;
        transform: translateY(-4px);
      }
    }
  }

  input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    flex-grow: 1;
    height: 3rem;
    margin: 10px 0;
    max-width: 400px;
  }
}
