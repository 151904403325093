@import '~assets/styles/shared';
@import 'sass-mq';

.provider-button {
  width: 100%;
  border: none;
  letter-spacing: 0;
  min-width: 200px;
}

@include mq($from: tablet) {
  .provider-button + .provider-button {
    margin-left: 60px;
  }
}

@include mq($until: tablet) {
  .provider-button {
    margin-left: 0;
    margin-right: 0;
    margin-top: 10px;
  }
}

.logo {
  margin-left: 16px;
  margin-right: 8px;
  height: 28px;
  width: 28px;
}

body[dir='rtl'] {
  @include mq($from: tablet) {
    .provider-button + .provider-button {
      margin-left: 0;
      margin-right: 15px;
    }
  }
}
