.icon {
  width: auto;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

// There should one one class for every CreditCardBrand's brand string.

.american-express {
  composes: icon;
  background-image: url('~assets/icons/amex.svg');
}

.diners-club {
  composes: icon;
  background-image: url('~assets/icons/diners-club.svg');
}

.discover {
  composes: icon;
  background-image: url('~assets/icons/discover.svg');
}

.jcb {
  composes: icon;
  background-image: url('~assets/icons/jcb.svg');
}

.mastercard {
  composes: icon;
  background-image: url('~assets/icons/master.svg');
}

.union-pay {
  composes: icon;
  background-image: url('~assets/icons/union-pay.svg');
}

.visa {
  composes: icon;
  background-image: url('~assets/icons/visa.svg');
}

.unknown {
  composes: icon;
  background-image: url('~assets/icons/generic-credit-card.svg');
}
