.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heroText {
  font-size: 1.5em;
}

.subHeroText {
  font-size: 1.17em;
}
