@import '~assets/styles/shared.scss';

.linesContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  max-width: 700px;
  margin: 8px 0;
}

.orderLine {
  display: flex;
  justify-content: space-between;
  font-size: $size-20;
}

.emphasis {
  font-weight: bold;
  font-size: $size-20;
}

.negative {
  color: $vds-color-magenta;
}

.header {
  font-size: 1em;
  text-align: center;
}
