@import '~assets/styles/shared.scss';

.employer_receipt_form {
  width: 100%;
  margin: 20px 0;

  .link {
    display: flex;
    align-items: center;
    font-size: $size-14;
    line-height: $size-14;
  }

  .icon_down {
    svg {
      transform: rotate(0deg);
    }
  }

  .icon_up {
    svg {
      transform: rotate(-180deg);
    }
  }

  :global .vds-button {
    margin-top: 20px;
  }
}
