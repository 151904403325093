@import '~assets/styles/shared';
@import '~sass-mq';

.formContainer {
  width: 100%;
}

.buttons {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

@include mq($until: tablet) {
  .buttons {
    flex-direction: column;
  }
}

.description {
  color: $vds-color-silver-lighter;
  line-height: 1.4;
  font-size: 14px;
  font-weight: 300;
  margin-left: 0;
  text-align: left;
}

body[dir='rtl'] {
  .description {
    text-align: right;
  }
}
