@import '~assets/styles/shared.scss';

.buttonWrapper {
  width: 100%;
  margin: 8px;
}

.selectorButton {

  background: transparent;
  border-bottom: 4px solid $vds-color-silver-lighter;
  border-left: none;
  border-right: none;
  border-top: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
}

.selectorButton:focus {
  outline: 0;
}

.selected {
  border-bottom: 4px solid $vds-color-cerulean;
}

.providerIcon {
  height: 24px;
}

.affirm_credit_icon_wrapper,
.paypal_icon_wrapper {
}
