@import '~assets/styles/shared';

.header {
  height: 72px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 0 0 32px 0;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: color(white);

  .center {
    img {
      height: 32px;
      width: 185px;
      margin: 0;
    }
  }
}
