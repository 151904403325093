.container {
  max-width: 632px;
  margin: 0 auto;
  padding: 16px;
}

.cartWrapper {
  margin-bottom: 64px;
}

.completeOrderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cartWrapper,
.completeOrderContainer {
  margin-bottom: 64px;
}

.errorContainer {
  margin-top: 32px;
}
