@import '~assets/styles/shared';

.paymentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 16px 0;
}

.tabBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 16px 0;
}

.nextButton {
  margin-top: 16px;
  text-align: center;
}

.paypalContainer {
  width: 200px;
}
