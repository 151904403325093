@import '~assets/styles/shared.scss';

.icon {
  svg {
    height: $size-24;
    width: $size-24;
    transition: transform 0.4s ease-out;
  }
}

.icon--up {
  svg {
    transform: rotate(-180deg);
  }
}

.icon--blue {
  svg {
    fill: $vds-color-cerulean;
  }
}

.icon--gray {
  svg {
    fill: $vds-color-silver-lighter;
  }
}
