@import '../../assets/styles/shared.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: 'Open Sans';
  font-weight: lighter;
  text-align: center;
  max-width: 600px;

  a {
    color: $vds-color-cerulean-dark;
    text-decoration: none;
  }
}

.title {
  font-size: 40px;
  font-weight: inherit;
  margin-bottom: 0;
  margin-top: 20px;
}

.subtitle {
  margin-top: 15px;
  font-weight: normal;
}

.description {
  font-size: 14px;
  margin-top: 20px;
}

.toggleTextButton {
  border: none;
  background: transparent;
  display: inline-block;
  color: $vds-color-cerulean-dark;
  cursor: pointer;
}
