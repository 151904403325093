// Fonts & Line Heights
$size-12: 0.75rem;
$size-14: 0.875rem;
$size-16: 1rem;
$size-18: 1.125rem;
$size-20: 1.25rem;
$size-22: 1.375rem;
$size-24: 1.5rem;
$size-28: 1.75rem;
$size-32: 2rem;
$size-34: 2.125rem;
$size-36: 2.25rem;
$size-40: 2.5rem;
$size-46: 2.875rem;

$app-max-width: 930px;
$main-card-max-width: 575px;

// Paddings
$pad-xs: 10px;
$pad-s: 15px;
$pad-m: 20px;
$pad-l: 30px;
$padding-default: $pad-m $pad-l;
$padding-small: $pad-s $pad-l;
$padding-mobile: $pad-s $pad-m;

// Borders
$border-gray: 1px solid $vds-color-silver-lighter;

$border-radius: 6px;
$border-radius-top: 6px 6px 0 0;
$border-radius-bottom: 0 0 6px 6px;

// Box shadows
$box-shadow: 0 0 5px 0 rgba(117, 124, 129, 0.12);
$box-shadow-large: 5px 5px 20px 0 rgba(0, 0, 0, 0.15);

// Gradients
$blue-green-gradient: linear-gradient(315deg, #02b3e4 0%, #02ccba 100%);

$MOBILE: 320;
$TABLET: 740;
$DESKTOP: 980;
