@import '~assets/styles/shared.scss';
@import '~sass-mq';

.form {
  input {
    line-height: 20px;
    height: 60px;
    color: $vds-color-slate;
  }
  margin-bottom: 20px;
}

