@import '~assets/styles/shared';

.card {
  align-content: center;
  border-radius: $border-radius;
  background: $blue-green-gradient;
  padding: 2px;
  box-shadow: $box-shadow-large;

  .card_inner {
    background-color: $vds-color-white;
    border-radius: $border-radius;
    overflow: hidden;
  }
}
