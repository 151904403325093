@import '~assets/styles/shared';

.contain {
  width: 100%;
  margin: 20px 0;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.line {
  width: 80%;
}

hr {
  width: 100%;
  height: 2px;
  background-color: $vds-color-silver-lighter;
  border: none;
  margin: 20px 0;
}

.text {
  margin: 0 10px;
  transform: translateY(-3px);
  min-width: 32px;
  font-weight: bold;
  font-size: 18px;
}
