@import '~assets/styles/shared.scss';
@import 'sass-mq';

.coupon_code_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: $size-14;
  line-height: $size-24;

  .link {
    display: flex;
    align-items: flex-start;
    margin-top: 0.5rem;
    color: $vds-color-silver-light !important;

    .icon {
      margin-left: 4px;
    }
  }

  .form_container {
    display: flex;
    align-items: baseline;

    button {
      margin-left: 16px;
    }
  }

  .coupon_description {
    font-style: italic;
    margin: 15px 0;
  }

  .coupon_error_message {
    margin-bottom: 15px;
    color: darken($vds-color-red, 15%);
  }
}
