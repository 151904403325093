@import '~@udacity/veritas-components/dist/styles/index.scss';
@import '~@udacity/veritas-design-tokens/dist/all.scss';
@import 'variables.scss';
@import 'sass-mq';

:global {
  /* Global styles go here, but you should rarely need to define these and instead should be using
     a local CSS module per component */
  body {
    background-color: $vds-color-silver-lightest;
  }
}

.link {
  text-decoration: none;
  color: $vds-color-cerulean-dark !important;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
}

.subheader {
  font-size: $size-12;
  font-weight: 600;
  text-transform: uppercase;
  color: $vds-color-silver;
  letter-spacing: 1px;
}

/* --- layout: main card + side card --- */

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: $app-max-width;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}

.main_card {
  flex: 1;
  display: flex;
  flex-direction: column;
  box-shadow: none;
  border: none;
  background-color: inherit;
  padding: 0;

  @include mq($from: tablet) {
    padding: 32px 40px;
    background-color: $vds-color-white;
    border: $border-gray;
    box-shadow: $box-shadow;
  }
}

.right_column {
  flex: 0 0 336px;
  margin-left: 25px;
}

.checkout_radio_selected {
  width: 0.5rem;
  height: 0.5rem;
  border: 0.5rem solid $vds-color-blue-dark;
  box-shadow: 0 2px 1px 0 rgba(46, 61, 73, 0.05);
  background: $vds-color-white;
}

@include mq($until: desktop) {
  .container {
    flex-direction: column;
    align-items: center;

    .right_column {
      flex: initial;
      width: 100%;
      margin: 25px auto;
    }
  }
}
