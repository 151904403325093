@import '~assets/styles/shared';

// Center the loading icon horizontally and vertically
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 95vh;
}

.loading_child {
  margin: auto;
}
